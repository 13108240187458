<template>
  <div>
    <div class="modal fade" id="view-report-modal">
      <div class="modal-dialog " style="max-width: 50%;">
        <div class="modal-content">
          <div class="modal-body">
            <table class="table table-sm table-bordered">
              <tbody>
                <tr>
                  <th colspan="4">Report Details</th>
                </tr>
                <tr>
                  <td width="20%">ID</td>
                  <th>CHED-R13-{{ report.id }}</th>
                </tr>
                <tr>
                  <td>Mode of Inspection</td>
                  <th>{{ report.mode_of_inspection }}</th>
                </tr>
                <tr>
                  <td>Academic Year</td>
                  <th>{{ report.academic_year }}</th>
                </tr>
                <tr>
                  <td>Program</td>
                  <th>{{ report.program_name }}</th>
                </tr>

                <tr>
                  <td>Name of HEI</td>
                  <th>{{ report.hei_name }}</th>
                </tr>

                <tr>
                  <td>Address</td>
                  <th>
                    {{ `${report.street}, ${report.city}, ${report.province}` }}
                  </th>
                </tr>

                <tr>
                  <td>Date and Time</td>
                  <th>{{ report.date_of_inspection | standardDate }}</th>
                </tr>
                <tr>
                  <td>Reference</td>
                  <th>{{ report.cmo }}</th>
                </tr>
              </tbody>
            </table>

            <div class="btn btn-group px-0 py-0">
              <button
                class="btn btn-outline-secondary text-dark"
                @click.prevent="print"
              >
                <i class="bi bi-printer mr-2"></i>Print
              </button>
            </div>

            <table class="table table-bordered table-sm mt-3 ">
              <caption class="py-0">
                <small>*C-Complied, NC-Not Complied</small>
              </caption>
              <thead>
                <th width="30%">Areas</th>
                <th width="10%">Complied</th>
                <th width="30%">Findings/Observations</th>
                <th>Remarks</th>
                <th width="10%">Action</th>
              </thead>
              <tbody>
                <tr class="text-center" v-if="tableLoader">
                  <td colspan="5">
                    <div class="spinner-border spinner-border-sm mr-1"></div>
                    Loading
                  </td>
                </tr>
                <tr v-for="(area, index) in areas" :key="area.id">
                  <td style="white-space:pre-line;">
                    <p class="font-weight-bold">{{ area.name }}</p>

                    {{ area.cmo_requirements }}
                  </td>

                  <td class="text-center">
                    <span
                      v-if="area.is_complied"
                      v-show="!area.editable"
                      class="font-weight-bold"
                      >&#10003;</span
                    >
                    <input
                      type="checkbox"
                      v-model="form.is_complied"
                      v-if="area.editable"
                      style="width: 16px; height: 16px;"
                    />
                  </td>
                  <td>
                    <ul class="pl-4 pr-0" v-if="!area.editable">
                      <li
                        v-for="(finding, index) in area.findings"
                        :key="index"
                      >
                        {{ `${finding.code} - ${finding.description}` }}
                      </li>
                    </ul>
                    <Multiselect
                      :options="findings"
                      :custom-label="codeWithDesc"
                      track-by="description"
                      placeholder="Choose"
                      v-model="form.findings"
                      :searchable="true"
                      :multiple="true"
                      v-show="area.editable"
                    ></Multiselect>
                  </td>
                  <td>
                    <p style="white-space: pre-wrap" v-if="!area.editable">
                      {{ area.remarks }}
                    </p>
                    <textarea
                      v-if="area.editable"
                      v-model="form.remarks"
                      class="form-control"
                      cols="30"
                    ></textarea>
                  </td>
                  <td class="text-center">
                    <div class="px-0">
                      <a
                        href="#"
                        class="mr-2"
                        @click.prevent="editArea(index, area)"
                        v-if="!area.editable"
                      >
                        <i class="bi bi-pencil"></i>
                      </a>
                      <a
                        href="#"
                        class="mr-2"
                        @click.prevent="area.editable = false"
                        v-if="area.editable"
                      >
                        X
                      </a>
                      <a href="#" @click.prevent="save" v-if="area.editable">
                        <i class="bi bi-save"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <!--         <div class="mt-4">
              <p>Compliance Documents</p>
              <ul>
                <li>
                  <a href="#">
                    <i class="bi bi-filetype-pdf"></i> Accession in Progress for
                    2nd Batch of Library Collection</a
                  >
                  <small class="text-muted form-text"
                    >{{ Date() | dateTime }} - PDF 2.4KB</small
                  >
                </li>
                <li>
                  <a href="#"> Periodicals and Subscriptions</a>
                  <small class="text-muted form-text"
                    >{{ Date() | dateTime }} - PDF 2.4KB</small
                  >
                </li>
                <li>
                  <a href="#">
                    Schedule of Proposed Tuition and Other Fees BSREM</a
                  >
                  <small class="text-muted form-text"
                    >{{ Date() | dateTime }} - PDF 2.4KB</small
                  >
                </li>
              </ul>

              <div class="mb-2">
                <a href="#"> Click here to upload file</a>
              </div>
            </div> -->
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>General remarks/recommendations</td>
                </tr>
                <tr>
                  <td>
                    <p style="white-space: pre-wrap;">
                      {{ report.general_remarks }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <PrintReport
      ref="PrintReport"
      :report="report"
      :areas="areas"
      v-show="false"
    ></PrintReport>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import PrintReport from './PrintReport.vue';

export default {
  name: 'ViewReportModal',
  components: {
    Multiselect,
    PrintReport,
  },
  data() {
    return {
      report: new Form({
        id: '',
        mode_of_inspection: '',
        date_of_inspection: '',
        program_name: '',
        hei_name: '',
        street: '',
        city: '',
        general_remarks: '',
        president: '',
        province: '',
        updated_by: '',
        academic_year: '',
        is_complied: '',
        created_at: '',
        cmo: '',
      }),
      areas: [],
      findings: [],
      form: new Form({
        id: '',
        findings: [],
        remarks: '',
        is_complied: '',
        index: '',
      }),
      tableLoader: false,
    };
  },
  methods: {
    open(report) {
      this.report.fill(report);
      $('#view-report-modal').modal('show');
      this.getAreasToMonitor(report.id, { loader: true });
    },

    getAreasToMonitor(report_id, config) {
      this.areas = [];
      this.tableLoader = config.loader;

      axios
        .get('api/program-monitoring-reports/areas-to-monitor/' + report_id)
        .then((res) => {
          this.areas = res.data.map((obj) => ({ ...obj, editable: false }));
          this.tableLoader = false;
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
    editArea(index, area) {
      this.areas[index].editable = true;
      this.form.fill(area);
      this.form.index = index;
    },
    save() {
      axios
        .put('api/program-monitoring-reports/' + this.form.id, this.form)
        .then((res) => {
          this.areas[this.form.index].editable = false;
          this.getAreasEvaluation(this.report.id, { loader: false });
        })
        .catch((err) => {});
    },
    getFindings() {
      axios.get('api/findings').then((res) => {
        this.findings = res.data;
      });
    },
    codeWithDesc({ code, description }) {
      return `${code} - ${description}`;
    },
    print() {
      this.$refs.PrintReport.print();
    },
  },
  mounted() {
    this.getFindings();
  },
};
</script>
