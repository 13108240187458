<template>
  <div>
    <form @submit.prevent="submit">
      <div class="d-flex justify-content-between align-items-center">
        <div class="form-row">
          <div class="col-auto">
            <div class="form-group">
              <label for="">Program</label>
              <select
                name=""
                id=""
                class="form-control"
                required
                v-model="reportDetails.program_id"
                @change="getProgramAreas"
                :disabled="!hei_programs.length"
              >
                <option value="" selected hidden disabled>Choose</option>
                <option
                  v-for="hei_program in hei_programs"
                  :key="hei_program.hei_program_id"
                  :value="hei_program.program_id"
                  >{{ hei_program.program_name }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="">Year</label>
              <select
                name=""
                id=""
                class="form-control"
                required
                v-model="reportDetails.academic_year"
              >
                <option value="" selected hidden disabled>Choose</option>
                <option value="2022-2023">2022-2023</option>
                <option value="2021-2022">2021-2022</option>
                <option value="2020-2021">2020-2021</option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="">Mode of Inspection</label>
              <select
                name=""
                id=""
                class="form-control"
                required
                v-model="reportDetails.mode_of_inspection"
              >
                <option value="" selected hidden disabled>Choose</option>
                <option value="On-site">On-site</option>
                <option value="Virtual">Virtual</option>
              </select>
            </div>
          </div>
          <div class="col-auto">
            <div class="form-group">
              <label for="">Date of Inspection</label>
              <input
                type="date"
                class="form-control"
                v-model="reportDetails.date_of_inspection"
                required
              />
            </div>
          </div>
        </div>
        <div class="">
          <button class="btn btn-link" @click.prevent="openAddFindingModal">
            Add Findings/Observations
          </button>
        </div>
      </div>

      <div class="mt-2">
        <table class="table table-bordered table-sm ">
          <thead>
            <th>#</th>
            <th>Areas of Evaluation</th>
            <th>Complied</th>
            <th width="30%">Findings/Observations</th>
            <th width="30%">Remarks/Recommendations</th>
          </thead>
          <tbody>
            <tr class="text-center" v-if="!programAreas.length">
              <td colspan="6">No area(s) added yet.</td>
            </tr>
            <tr v-for="(area, index) in programAreas" :key="area.id">
              <th>{{ index + 1 }}</th>
              <td style="white-space:pre-line;">
                <p class="font-weight-bold">
                  {{ area.name }}
                </p>
                {{ area.cmo_requirements }}
              </td>
              <td>
                <div class="form-check">
                  <input
                    v-model="area.is_complied"
                    type="checkbox"
                    class="form-check-input"
                    style="width: 16px; height: 16px;"
                  />
                </div>
              </td>
              <td>
                <Multiselect
                  :options="area.options"
                  :custom-label="codeWithDesc"
                  track-by="description"
                  v-model="area.findings"
                  placeholder="Choose"
                  :searchable="true"
                  :multiple="true"
                ></Multiselect>
              </td>
              <td>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="3"
                  class="form-control"
                  v-model="area.remarks"
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="form-group mt-3">
        <label for="">General remarks</label>
        <textarea
          name=""
          id=""
          cols="30"
          rows="3"
          class="form-control"
          v-model="reportDetails.general_remarks"
        ></textarea>
      </div>

      <div class="form-check mt-4">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          v-model="reportDetails.is_complied"
          :disabled="!programAreas.length"
        />
        <label class="form-check-label">
          Check if all documents presented were in accordance to its
          corresponding PSGs.
        </label>
      </div>

      <div class="mt-4">
        <button
          id="submit-report-btn"
          type="submit"
          class="btn btn-primary  mr-2"
        >
          Submit
        </button>
        <button class="btn btn-link" @click.prevent="resetForm">
          Clear form
        </button>
      </div>

      <p class="mt-3">
        {{ `${$store.state.user.first_name} ${$store.state.user.last_name}` }}
        ({{ Date() | dateTime }})
      </p>
    </form>

    <AddFindingModal
      ref="AddFindingModal"
      :areas="areas"
      v-on:addFinding="addNewFinding"
    ></AddFindingModal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import AddFindingModal from './AddFindingModal.vue';

export default {
  name: 'AddMonitoringReport',
  components: {
    Multiselect,
    AddFindingModal,
  },
  data() {
    return {
      value: '',
      hei_programs: [],
      a_val: [],
      options: [],
      programAreas: [],
      selectedAreas: [],
      currentArea: '',
      findings: [],
      areas: [],
      checkbox: false,
      reportDetails: {
        hei_code: this.$route.params.id,
        program_id: '',
        academic_year: '',
        mode_of_inspection: '',
        date_of_inspection: '',
        general_remarks: '',
        is_complied: false,
      },
    };
  },
  methods: {
    submit() {
      if (!this.programAreas.length) {
        toastr.error('No area/s added.', 'Error');
        return;
      }

      if (confirm('Are you sure you want to submit this report?')) {
        let b = document.getElementById('submit-report-btn');
        b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Submitting...`;
        b.disabled = true;
        axios
          .post('api/program-monitoring-reports', {
            data: this.reportDetails,
            areas: this.programAreas,
          })
          .then((res) => {
            toastr.success(res.data.msg, 'Information');
            this.resetForm();
            b.innerHTML = 'Submit report';
            b.disabled = false;
          })
          .catch((err) => {
            b.innerHTML = 'Submit report';
            b.disabled = false;
          });
      } else returnl;
    },
    codeWithDesc({ code, description }) {
      return `${code} - ${description}`;
    },
    getHeiPrograms() {
      axios
        .get('api/hei-programs/' + this.$route.params.id)
        .then((response) => {
          this.hei_programs = response.data;
          this.getFindings();
        })
        .catch((error) => {
          toastr.error('Something went wrong', 'Request failed');
        });
    },

    getProgramAreas() {
      axios
        .get('api/programs/areas/' + this.reportDetails.program_id)
        .then((res) => {
          this.programAreas = res.data.map((obj) => {
            return {
              ...obj,
              is_complied: '',
              findings: [],
              remarks: '',
              options: this.findings.filter((opt) => {
                return opt.area_id == obj.area_id;
              }),
            };
          });
        })
        .catch((err) => {
          toastr.error('Unable to fetch areas.', 'Oops! Something went wrong.');
        });
    },

    getFindings() {
      axios.get('api/findings').then((res) => {
        this.findings = res.data;
      });
    },

    getAreas() {
      axios.get('api/areas').then((res) => {
        this.areas = res.data;
      });
    },

    resetForm() {
      Object.keys(this.reportDetails).forEach(
        (key) => (this.reportDetails[key] = '')
      );
      this.programAreas = [];
    },
    openAddFindingModal() {
      this.$refs.AddFindingModal.open();
    },
    addNewFinding(finding) {
      let index = this.selectedAreas.findIndex(
        (area) => area.area_id == finding.area_id
      );
      this.selectedAreas[index].options.push(finding);
    },
  },
  mounted() {
    this.getHeiPrograms();
    this.getAreas();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
