<template>
  <div>
    <div>
      <table
        id="view-pm-reports"
        class="table table-hover table-bordered table-sm"
      >
        <thead>
          <th>REPORT ID</th>
          <th>PROGRAM</th>
          <th>HEAD</th>
          <th>MODE OF INSPECTION</th>
          <th>STATUS</th>
          <th>SUBMITTED BY</th>
          <th>DATE OF SUBMISSION</th>
        </thead>

        <tbody>
          <tr class="text-center" v-if="!reports.length">
            <td colspan="9">No report(s) added yet.</td>
          </tr>
          <tr
            v-for="report in reports"
            :key="report.id"
            @click.stop="showModal(report)"
          >
            <td>CHED-R13-{{ report.id }}</td>
            <td>
              {{ report.program_name }}
            </td>
            <td>{{ report.program_chair }}</td>
            <td>{{ report.mode_of_inspection }}</td>
            <td>
              <span
                v-if="report.is_complied"
                class="badge badge-pill badge-success"
                >Complied</span
              >
              <span v-else class="badge badge-pill badge-danger"
                >Not complied</span
              >
            </td>
            <td>{{ report.updated_by }}</td>
            <td>{{ report.date_of_inspection | standardDate }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <ViewReportModal ref="ViewReportModal"></ViewReportModal>
  </div>
</template>

<script>
import ViewReportModal from './ViewReportModal.vue';

export default {
  name: 'ViewReports',
  components: {
    ViewReportModal,
  },
  data() {
    return {
      reports: [],
    };
  },
  methods: {
    showModal(report) {
      this.$refs.ViewReportModal.open(report);
    },
    getReports() {
      axios
        .get('api/program-monitoring-reports/' + this.$route.params.id)
        .then((res) => {
          this.reports = res.data;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getReports();
  },
};
</script>

<style scoped>
#view-pm-reports tr {
  cursor: pointer;
}
</style>
