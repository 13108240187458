<template>
  <div id="print-monitoring-report">
    <header class="text-center mt-5">
      <img
        src="@/assets/images/ched-header-logo-big.png"
        width="500"
        height="132"
        alt="CHED Header Logo"
      />
    </header>

    <header class="text-center mt-5" style="font-size: 19px;">
      <h4 class="font-weight-bold mb-0">
        INSTITUTIONAL MONITORING AND EVALUATION REPORT
      </h4>
      <p>A.Y. {{ report.academic_year }}</p>
    </header>
    <table class="table table-sm mt-4" style="font-size: 19px;">
      <tbody>
        <tr>
          <th style="border: 1px solid black !important" colspan="4">
            Report Details
          </th>
        </tr>
        <tr>
          <td style="border: 1px solid black !important" width="20%">ID</td>
          <th style="border: 1px solid black !important">
            CHED-R13-{{ report.id }}
          </th>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">Mode of Inspection</td>
          <th style="border: 1px solid black !important">
            {{ report.mode_of_inspection }}
          </th>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">Program</td>
          <th style="border: 1px solid black !important">
            {{ report.program_name }}
          </th>
        </tr>

        <tr>
          <td style="border: 1px solid black !important">Name of HEI</td>
          <th style="border: 1px solid black !important">
            {{ report.hei_name }}
          </th>
        </tr>

        <tr>
          <td style="border: 1px solid black !important">Address</td>
          <th style="border: 1px solid black !important">
            {{ `${report.street}, ${report.city}, ${report.province}` }}
          </th>
        </tr>

        <tr>
          <td style="border: 1px solid black !important">Date and Time</td>
          <th style="border: 1px solid black !important">
            {{ report.date_of_inspection | standardDate }}
          </th>
        </tr>

        <tr>
          <td style="border: 1px solid black !important">Reference</td>
          <th style="border: 1px solid black !important">
            {{ report.cmo }}
          </th>
        </tr>
      </tbody>
    </table>

    <table
      class="table table-sm mt-5"
      style="font-size: 19px; border-color: black !important;"
    >
      <caption class="py-0">
        <small>*C-Complied, NC-Not Complied</small>
      </caption>
      <thead class="text-center">
        <th style="border: 1px solid black !important" width="25%">Areas</th>
        <th style="border: 1px solid black !important" width="10%">Status</th>
        <th style="border: 1px solid black !important" width="30%">
          Findings/Observations
        </th>
        <th style="border: 1px solid black !important">Remarks</th>
      </thead>
      <tbody style="border-color: black !important;">
        <tr v-for="(area, index) in areas" :key="area.id">
          <td
            style="border: 1px solid black !important; white-space: pre-line;"
          >
            <p>
              {{ index + 1 }}. <strong>{{ area.name }}</strong>
            </p>
            {{ area.cmo_requirements }}
          </td>

          <td style="border: 1px solid black !important" class="text-center">
            <span v-if="area.is_complied" class="">C</span>
            <span v-else>NC</span>
          </td>
          <td style="border: 1px solid black !important">
            <ul class="pl-4 pr-0">
              <li v-for="(finding, index) in area.findings" :key="index">
                {{ finding.description }}
              </li>
            </ul>
          </td>
          <td style="border: 1px solid black !important">
            <p style="white-space:pre-wrap">{{ area.remarks }}</p>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table mt-5" style="font-size: 19px;">
      <tbody>
        <tr>
          <th style="border: 1px solid black !important">
            General remarks/recommendations
          </th>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">
            <p style="white-space:pre-wrap">{{ report.general_remarks }}</p>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table table-bordered mt-5" style="font-size: 19px;">
      <tbody>
        <tr>
          <td style="border: 1px solid black !important" colspan="4">
            Evaluated and validated by
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">
            <strong class="mb-0">{{ report.updated_by.toUpperCase() }}</strong>
            <span class="form-text mt-0">Education Supervisor II</span>
          </td>
          <td style="border: 1px solid black !important">
            <small>Signature</small>
          </td>
          <td style="border: 1px solid black !important">
            <small class="">Date and Time</small>
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important" colspan="4">
            Noted by
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">
            <strong class="mb-0">JULIA FELISA C. MARTINEZ, PhD, LPT</strong>
            <span class="form-text mt-0"
              >Chief Education Program Specialist</span
            >
          </td>

          <td style="border: 1px solid black !important">
            <small>Signature</small>
          </td>
          <td style="border: 1px solid black !important">
            <small class="">Date and Time</small>
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">
            <strong class="mb-0">GEORGE M. COLORADO, PhD, CESO III</strong>
            <span class="form-text mt-0">Director IV</span>
          </td>

          <td style="border: 1px solid black !important">
            <small>Signature</small>
          </td>
          <td style="border: 1px solid black !important">
            <small class="">Date and Time</small>
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important" colspan="4">
            Conforme
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">
            <strong class="mb-0 invisible">HEI</strong>
            <span class="form-text mt-0">HEI Representative</span>
          </td>

          <td style="border: 1px solid black !important">
            <small>Signature</small>
          </td>
          <td style="border: 1px solid black !important">
            <small class="">Date and Time</small>
          </td>
        </tr>
        <tr>
          <td style="border: 1px solid black !important">
            <strong class="mb-0">{{ report.president.toUpperCase() }}</strong>
            <span class="form-text mt-0">HEI President/Head</span>
          </td>
          <td style="border: 1px solid black !important">
            <small>Signature</small>
          </td>
          <td style="border: 1px solid black !important">
            <small class="">Date and Time</small>
          </td>
        </tr>
      </tbody>
    </table>

    <p class="mt-5" style="font-size: 19px;">
      Printed: {{ Date() | dateTime }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrintReport',
  props: {
    report: {
      required: true,
    },
    areas: {
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    print() {
      setTimeout(() => {
        this.$htmlToPaper('print-monitoring-report');
      });
    },
  },
};
</script>
